import { theme } from '@/theme';
import { Trans } from '@chillworks/gatsby-plugin-react-i18next-root';
import { LoadingOverlay } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useLocation } from '@reach/router';
import {
  signIn as AmpSignIn,
  signOut as AmpSignOut,
  AuthError,
  getCurrentUser,
  signUp
} from 'aws-amplify/auth';
import { navigate } from 'gatsby';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { api } from '../graphql-api/base-api';
import { usePermissions } from '../hooks/use-permissions';
import { useAppDispatch } from '../store/hooks';

export interface SigninInput {
  email: string;
  password: string;
}

export interface AuthContextData {
  isAuthenticated: () => Promise<boolean>;
  setLastLocation: (location: string) => void;
  signIn: (input: SigninInput) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: typeof signUp;
}

const AuthContext = createContext<AuthContextData | null>(null);

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context!;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [lastLocation, _setLastLocation] = useState('/');
  const dispatch = useAppDispatch();

  const signIn = useCallback(
    async (input: SigninInput) => {
      try {
        await AmpSignIn({
          password: input.password,
          username: input.email
        });

        await navigate(lastLocation);
      } catch (error) {
        if (error instanceof AuthError) {
          if (error.name === 'UserAlreadyAuthenticatedException') {
            // log out first?
          }

          if (error.name === 'UserNotFoundException') {
            notifications.show({ message: 'User does not exist' });
          }
        }
      }
    },
    [lastLocation]
  );

  const signOut = useCallback(async () => {
    await AmpSignOut();
    dispatch(api.util.resetApiState());
    await navigate('/sign-in');
  }, []);

  const setLastLocation = useCallback(
    (pathname: string) => _setLastLocation(pathname),
    [_setLastLocation]
  );

  const isAuthenticated = useCallback(
    async () =>
      await getCurrentUser()
        .then(() => true)
        .catch(() => false),
    []
  );

  const context = useMemo(
    () => ({
      isAuthenticated,
      setLastLocation,
      signIn,
      signOut,
      signUp
    }),
    [isAuthenticated, setLastLocation, signIn, signOut, signUp]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

interface AuthGuardProps {
  requiredPermissions?: string[];
}

export const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({
  children,
  requiredPermissions
}) => {
  const [isLoading, setIsloading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { pathname } = useLocation();
  const { setLastLocation } = useAuth();
  const { isAllowed, isLoading: permissionsAreloading } =
    usePermissions(requiredPermissions);

  useEffect(() => {
    setLastLocation(pathname);

    getCurrentUser()
      .then(() => setIsAuthenticated(true))
      .catch(() => navigate('/sign-in'))
      .finally(() => setIsloading(false));
  }, []);

  if (permissionsAreloading || isLoading || !isAuthenticated) {
    return (
      <LoadingOverlay
        loaderProps={{ color: theme.primaryColor, type: 'bars' }}
        overlayProps={{ blur: 2, radius: 'sm' }}
        visible
        zIndex={1000}
      />
    );
  }

  if (isAuthenticated && isAllowed) {
    return <>{children}</>;
  }

  notifications.show({
    color: 'red',
    message: (
      <Trans i18nKey="page-forbidden-access-message" ns="common">
        You cannot access this page
      </Trans>
    ),
    title: (
      <Trans i18nKey="page-forbidden-access-title" ns="common">
        Forbidden access
      </Trans>
    )
  });

  navigate('/', { replace: true });

  return null;
};

export function withAuthGuard<P extends {}>(
  Component: FC<P>,
  authGuardProps?: AuthGuardProps
): FC<P> {
  return (props) => (
    <AuthGuard {...authGuardProps}>
      <Component {...props} />
    </AuthGuard>
  );
}
